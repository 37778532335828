//////////////////////////////////////////////////////////////////////////////
// Imports

import SmoothScroll from 'smooth-scroll';
import axios from 'axios';
import $ from 'cash-dom';

//////////////////////////////////////////////////////////////////////////////
// Page Load

window.onload = function() {
	setTimeout(revealPage, 500);
}

function revealPage() {
	$("body").removeClass("loading");
}

//////////////////////////////////////////////////////////////////////////////
// Smooth scroll to internal anchor links

var scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1250,
	speedAsDuration: true,
	easing: 'easeInOutQuart'
});

//////////////////////////////////////////////////////////////////////////////
// Full width images with captions

$('p').has('img').css('width','calc(100% + 1px)');

$.each($("p img"), function() {
	$(this).wrap("<figure></figure>");
	var altText = $(this).attr('alt');
	$(this).after('<figcaption>'+altText+'</figcaption>');
});

//////////////////////////////////////////////////////////////////////////////
// Navigation events

// Toggle Nav: Click Nav Menu
$('.menu').on('click', function() {
	toggleMenuCheck();
});

// Toggle Nav: Press "Escape"
$(document).on('keyup', function(e) {
	if(e.which == 27) { // Escape
		toggleMenuCheck();
	}
});

function toggleMenuCheck() {
	var viewportHeight = $(window).height();
	var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

	if(scrollTop > (viewportHeight / 2)){
		$('body').toggleClass('menu-open');
		navReflow();
	} else {
		var scroll = new SmoothScroll();
		var anchor = document.querySelector('#monospace');
		scroll.animateScroll(anchor);
		setTimeout(toggleMenu, 400);
	}
}

function toggleMenu() {
	$('body').toggleClass('menu-open');
	navReflow();
}

function navReflow() {
	$('.overlay li').each(function( index ) {
		$(this).css('animation', 'none');
		$(this).outerHeight(); // Force reflow to reset css animation
		$(this).css('animation', null);
	});
}

// Click Nav Item
$('#navigation nav li').on('click', function() {
	$('body').removeClass('menu-open');
	navReflow();
});

//////////////////////////////////////////////////////////////////////////////
// Show button when email exists

$('form#email input').on('keyup', function() {
	let email = document.getElementById("email_input");
	if(!email || email.value.length == 0 || !email.validity.valid){
		$('form#email').removeClass('enabled');
	} else {
		$('form#email').addClass('enabled');
	}
});

$('form#email input').on('blur', function() {
	let email = document.getElementById("email_input");
	if(!email || email.value.length == 0 || !email.validity.valid){
		$('form#email').removeClass('enabled');
	} else {
		$('form#email').addClass('enabled');
	}
});

//////////////////////////////////////////////////////////////////////////////
// Effects on enter viewport

var $win = $(window);
var $vis = $('#visible');
var fadeThreshold = 33;

$win.on('scroll', function () {
	var viewportHeight = $(window).height(),
			scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

	if((scrollTop < (viewportHeight / 2)) && $('body').hasClass('menu-open')){
		$('body').removeClass('menu-open');
		navReflow();
	}

	$('.fadeIn').each(function (index, value) {
		let percentSeen = percentageSeen( $(this) );
		if (percentSeen > 0 && percentSeen < fadeThreshold){

			// Condense elements
			$(this).find('h1')
				.css('padding-top', (fadeThreshold - percentSeen)*2 + 'px')
				.css('padding-bottom', (fadeThreshold - percentSeen)*2 + 'px');

			let newsOpacity = percentSeen / fadeThreshold;
			$(this).css('opacity', newsOpacity);
		} else if (percentSeen > 0 && percentSeen < 100) {
			$(this).css('opacity', 1);
			$(this).find('h1')
				.css('padding-top', '0px')
				.css('padding-bottom', '0px');
		}
	});
	$('.fadeOut').each(function (index, value) {
		let percentSeen = percentageSeen( $(this) );
		if (percentSeen > (100 - fadeThreshold) && percentSeen < 100){
			let invertedPercent = Math.abs(percentSeen - 100);
			let newsOpacity = invertedPercent / fadeThreshold;
			$(this).css('opacity', newsOpacity);
		} else if (percentSeen > 0 && percentSeen < 100) {
			$(this).css('opacity', 1);
		}
	});
});

function percentageSeen (el) {
	var $element = el;
	var viewportHeight = $(window).height(),
			scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop, // $win.scrollTop(),
			elementOffsetTop = $element.offset().top,
			elementHeight = $element.height();

	if (elementOffsetTop > (scrollTop + viewportHeight)) {
		return 0;
	} else if ((elementOffsetTop + elementHeight) < scrollTop) {
		return 100;
	} else {
		var distance = (scrollTop + viewportHeight) - elementOffsetTop;
		var percentage = distance / ((viewportHeight + elementHeight) / 100);
		percentage = Math.round(percentage);
		return percentage;
	}
}

$win.trigger('scroll');

//////////////////////////////////////////////////////////////////////////////
// Submit email form

var emailForm = document.querySelector('#email');
var emailInput = document.querySelector('#email input');

emailForm.addEventListener('submit', function(event) {
	event.preventDefault();

	var payload = {
		email: emailInput.value
	};

	axios.post('https://api.directus.cloud/dcMcIx1BRX5eh0gp/items/mailing_list', payload)
		.then(() => {
			emailForm.dataset.status = 'success';
		})
		.catch(() => {
			emailForm.dataset.status = 'failure';
		});
});

//////////////////////////////////////////////////////////////////////////////
